<div class="container" id="creating">
  <div class="title">
    <h2>НАШІ <span class="color-title">ПЕРЕВАГИ</span></h2>
  </div>

 <div class="wrapper">
   <div class="item-list">
     <div class="item">
       <h3 class="color-title">10+</h3>
       <p class="item-description">Років лідерства на ринку</p>
     </div>
     <div class="item">
       <h3 class="color-title">200+</h3>
       <p  class="item-description">ДОМІВОК ОСББ СПІВПРАЦЮЄ З НАМИ</p>
     </div>
     <div class="item">
       <h3 class="color-title">7</h3>
       <p  class="item-description">Послуг які допоможуть будинку розвиватися</p>
     </div>
     <div class="item">
       <h3 class="color-title">200+</h3>
       <p  class="item-description">Створених “ОСББ” у нашому місті</p>
     </div>
   </div>
 </div>
</div>
