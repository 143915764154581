import {WorkerPhotoInterface} from "../interfaces/worker-photo.interface";

export const galleryData: WorkerPhotoInterface[] = [
  {
    name: 'Анастасія Юріївна',
    job: 'Директор',
    imgUrl: 'assets/images/team-photos/1.png'
  },
  {
    name: 'Катерина Юріївна Забірна',
    job: 'Засновник',
    imgUrl: 'assets/images/team-photos/2.png'
  },
  {
    name: 'Потапова Катерина Сергіївна',
    job: 'Головний бухалтер',
    imgUrl: 'assets/images/team-photos/3.png'
  }
];
