<div class="header-container">
  <div class="header-logo">
    <img src="assets/images/logo/logo.svg" alt="logo-image" width="144" height="65">
  </div>

  <div class="header-menu">
    <div class="nav-row">
      <a href="#aboutCompany" aria-label="ПРО НАС">ПРО НАС</a>
      <a href="#services" aria-label="ПОСЛУГИ">ПОСЛУГИ</a>
      <a href="#creating" aria-label="СПІВПРАЦЯ">СПІВПРАЦЯ</a>
      <a href="#address" aria-label="КОНТАКТИ">КОНТАКТИ</a>
    </div>

    <div class="header-info">
      <div class="phone-number" aria-label="0(800) 33-11-35">0(800) 33-11-35</div>
      <div class="email" aria-label="scsputnik@ukr.net">scsputnik@ukr.net</div>
    </div>
  </div>

  <div class="header-btn-wrapper">
    <button (click)="openDialog()">Зв'яжіться з нами</button>
  </div>
</div>


