import {Component, ElementRef, inject, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

import {galleryData} from "./data/gallery.data";
import {ContactsModalDialogComponent} from "../../shared/popups/contacts-modal-dialog/contacts-modal-dialog.component";

@Component({
  selector: 'app-about-company',
  templateUrl: './about-company.component.html',
  styleUrls: ['./about-company.component.scss']
})
export class AboutCompanyComponent {
  @ViewChild('photoList') photoListRef!: ElementRef;
  public readonly photos = galleryData;
  private matDialog = inject(MatDialog);
  private itemWidth = 150;
  private padding = 150;

  goLeft() {
    this.photoListRef.nativeElement.scrollLeft -= (this.itemWidth + this.padding);
  }

  goRight() {
    this.photoListRef.nativeElement.scrollLeft += (this.itemWidth + this.padding);
  }

  openDialog() {
    this.matDialog.open(ContactsModalDialogComponent);
  }
}
