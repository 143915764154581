import {Component, inject} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

import {ContactsModalDialogComponent} from "../../shared/popups/contacts-modal-dialog/contacts-modal-dialog.component";

@Component({
  selector: 'app-about-create-company',
  templateUrl: './about-create-company.component.html',
  styleUrls: ['./about-create-company.component.scss']
})
export class AboutCreateCompanyComponent {
  private matDialog = inject(MatDialog);

  openDialog() {
    this.matDialog.open(ContactsModalDialogComponent);
  }
}
