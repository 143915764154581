<div class="container">
  <div class="cansell" (click)="closePopups()">
    <img src="assets/images/icons/popups/cansell.svg" alt="cansell-icon">
  </div>
  <h3>У ВАС ЗАЛИШИЛИСЯ <span>ПИТАННЯ</span>?</h3>
  <p>Телефонуйте і ми із задоволенням відповімо на ваші ворпоси!</p>

  <div class="block-info">
    <div class="info-wrap">
      <div class="icon">
        <img src="assets/images/icons/general/phone.svg" alt="phone-icon">
      </div>
      <p><a href="tel:+30800331135" aria-label="company phone number 0(800) 33-11-35">0(800) 33-11-35</a></p>
    </div>
    <div class="info-wrap">
      <div class="icon">
        <img src="assets/images/icons/general/mail.svg" alt="mail-icon">
      </div>
      <p><a href="mailto:scsputnik@ukr.net" aria-label="company email scsputnik@ukr.net">scsputnik@ukr.net</a></p>
    </div>
    <div class="info-wrap">
      <div class="icon">
        <img src="assets/images/icons/general/address.svg" alt="address-icon">
      </div>
      <p>м.Дніпро пр. Пушкіна, 11</p>
    </div>
    <div class="info-wrap">
      <div class="icon">
        <img src="assets/images/icons/general/clock.svg" alt="work-time-icon">
      </div>
      <p>у будні з 8:00 до 17:00</p>
    </div>
  </div>

</div>
