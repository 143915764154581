import {Component, inject} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ContactsModalDialogComponent} from "../../shared/popups/contacts-modal-dialog/contacts-modal-dialog.component";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  private matDialog = inject(MatDialog);

  openDialog() {
    this.matDialog.open(ContactsModalDialogComponent);
  }
}
