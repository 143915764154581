import {Component, inject} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'app-contacts-modal-dialog',
  templateUrl: './contacts-modal-dialog.component.html',
  styleUrls: ['./contacts-modal-dialog.component.scss']
})
export class ContactsModalDialogComponent {
  private matDialog = inject(MatDialog);

  closePopups () {
    this.matDialog.closeAll();
  }
}
