<div class="container" id="address">
  <div class="title">
    <div class="part-4">У ВАС ЗАЛИШИЛИСЯ ПИТАННЯ?</div>
    <div class="part-3">ПРІЇДЖАЙТЕ ДО НАС В <span>ОФІС</span></div>
    <p>МИ З ЗАДОВОЛЕННЯМ ВІДПОВІДІМО І ДОПОМОЖЕМО.</p>
  </div>

  <div class="wrap-content">
    <a href="https://www.google.com.ua/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF
    .+%D0%9B%D0%B5%D1%81%D0%B8+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%BA%D0%B8,
    +11,+%D0%94%D0%BD%D0%B5%D0%BF%D1%80,
    +%D0%94%D0%BD%D0%B5%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F
    +%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+49000/@48.4644171,35.0302992,17.69z/
    data=!4m6!3m5!1s0x40dbe2e3a61eebef:0x39a7526f54ad87fd!8m2!3d48.4643275!4d35.
    0317624!16s%2Fg%2F11f_35f7s7?hl=ru&entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D" aria-label="google map" target="_blank">
      <div class="for-link"></div>
    </a>

    <img class="map1"  src="assets/images/section-background/map1.png" alt="map" width="1280" height="741">

    <div class="block-info">
      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/phone.svg" alt="phone-icon">
        </div>
        <p><a href="tel:+30800331135" aria-label="company phone 0(800) 33-11-35">0(800) 33-11-35</a></p>
      </div>

      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/mail.svg" alt="mail-icon">
        </div>
        <a href="mailto:scsputnik@ukr.net" aria-label="company scsputnik@ukr.net">scsputnik@ukr.net</a>
      </div>

      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/address.svg" alt="address-icon">
        </div>
        <p>м.Дніпро пр. Пушкіна, 11</p>
      </div>

      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/clock.svg" alt="work-time-icon">
        </div>
        <p>у будні з 8:00 до 17:00</p>
      </div>
    </div>
  </div>

  <div class="wrap-mob-content">
    <a href="https://www.google.com.ua/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF
    .+%D0%9B%D0%B5%D1%81%D0%B8+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%BA%D0%B8,
    +11,+%D0%94%D0%BD%D0%B5%D0%BF%D1%80,
    +%D0%94%D0%BD%D0%B5%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F
    +%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+49000/@48.4644171,35.0302992,17.69z/
    data=!4m6!3m5!1s0x40dbe2e3a61eebef:0x39a7526f54ad87fd!8m2!3d48.4643275!4d35.
    0317624!16s%2Fg%2F11f_35f7s7?hl=ru&entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D" aria-label="company map" target="_blank">
      <div class="for-link-mob"></div>
    </a>
    <img class="mob-map-img" src="assets/images/section-background/map-mob.png" alt="map">

    <div class="block-info-mob">
      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/phone.svg" alt="phone-icon">
        </div>
        <p><a href="tel:+30800331135" aria-label="company phone">0(800) 33-11-35</a></p>
      </div>

      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/mail.svg" alt="mail-icon">
        </div>
        <a href="mailto:scsputnik@ukr.net" aria-label="company email">scsputnik@ukr.net</a>
      </div>

      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/address.svg" alt="address-icon">
        </div>
        <p>м.Дніпро пр. Пушкіна, 11</p>
      </div>

      <div class="info-wrap">
        <div class="icon">
          <img src="assets/images/icons/general/clock.svg" alt="work-time-icon">
        </div>
        <p>у будні з 8:00 до 17:00</p>
      </div>
    </div>
  </div>
</div>
