import {Component, ElementRef, ViewChild} from '@angular/core';

import {comments} from "./data/comments.data";


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @ViewChild('commentsList') commentsListRef!: ElementRef;
  public comments = comments;
  public array = Array;
  private itemWidth = 490;
  private padding = 510;

  goLeft() {
    this.commentsListRef.nativeElement.scrollLeft -= (this.itemWidth + this.padding)
  }
  goRight() {
    this.commentsListRef.nativeElement.scrollLeft += (this.itemWidth + this.padding)
  }

  openComments() {
    window.open("https://www.google.com/search?q=%D1%81%D0%BF%D1%83%D1%82%D0%BD%D0%B8%D0%BA+%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B+%D0%B4%D0%BD%D0%B5%D0%BF%D1%80&client=safari&sca_esv=9267af3241730e66&sca_upv=1&rls=en&sxsrf=ADLYWILA9vxdcGmxAmjuA-lpdkY4SOf3eA%3A1722588436985&ei=FJ2sZrDmO6S0wPAPzaPOkAU&ved=0ahUKEwiwwIX_9dWHAxUkGhAIHc2RE1IQ4dUDCA8&uact=5&oq=%D1%81%D0%BF%D1%83%D1%82%D0%BD%D0%B8%D0%BA+%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B+%D0%B4%D0%BD%D0%B5%D0%BF%D1%80&gs_lp=Egxnd3Mtd2l6LXNlcnAiJtGB0L_Rg9GC0L3QuNC6INC-0YLQt9GL0LLRiyDQtNC90LXQv9GAMgUQIRigATIFECEYoAEyBRAhGJ8FMgUQIRifBTIFECEYnwUyBRAhGJ8FMgUQIRifBTIFECEYnwUyBRAhGJ8FMgUQIRifBUi3D1CFAljPDXABeAGQAQCYAZYBoAGRBqoBAzEuNbgBA8gBAPgBAZgCB6ACtAbCAgoQABiwAxjWBBhHwgINEAAYgAQYsAMYQxiKBcICBRAAGIAEwgIGEAAYFhgewgIIEAAYFhgeGA_CAggQABiABBiiBMICBBAhGBWYAwCIBgGQBgmSBwMyLjWgB7Yy&sclient=gws-wiz-serp#lrd=0x40dbe3a7144509d7:0x9ec6f83979de05f6,1,,,,")
  }
}
