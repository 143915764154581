<div class="container">
  <div class="content-wrapper">
    <h1>КОМПАНІЯ <span class="color-title"> СУПУТНИК</span></h1>
    <div class="part-4">Сервіс для ОСББ</div>
    <div class="btn" (click)="openDialog()">Наші контакти</div>
  </div>

  <div class="content-img">
    <img src="assets/images/section-background/1.webp" alt="content-img" width="947" height="541">
  </div>
</div>

<div class="mobile-container">
  <div class="content-img-logo">
    <img src="assets/images/logo/logo-mob.svg" alt="content-img" width="144" height="65">
  </div>

  <div class="content-img-mobile">
    <img src="assets/images/section-background/1-mobil.png" alt="content-img" width="1532" height="1148">
  </div>

  <div class="content-wrapper-mobile">
    <h2>КОМПАНІЯ</h2>
    <h2 class="color-title">СУПУТНИК</h2>
    <div class="text part-4">Сервіс для ОСББ</div>
  </div>

  <div class="btn" (click)="openDialog()">Наші контакти</div>
</div>
