import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/layouts/header/header.component';
import { FooterComponent } from './core/layouts/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomePageComponent } from './components/home-page/home-page.component';
import { AboutCompanyComponent } from './components/about-company/about-company.component';
import { AboutWorkComponent } from './components/about-work/about-work.component';
import { WorkTimeComponent } from './components/work-time/work-time.component';
import { AboutCreateCompanyComponent } from './components/about-create-company/about-create-company.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CompanyMapComponent } from './components/company-map/company-map.component';
import { ContactsModalDialogComponent } from './shared/popups/contacts-modal-dialog/contacts-modal-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    AboutCompanyComponent,
    AboutWorkComponent,
    WorkTimeComponent,
    AboutCreateCompanyComponent,
    CommentsComponent,
    CompanyMapComponent,
    ContactsModalDialogComponent,
  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      MatDialogModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
