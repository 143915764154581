<div class="footer">
  <h3 class="footer-title">компанія Супутник у Дніпрі надає повний спектр послуг з обслуговування ОСББ.</h3>

  <div class="footer-container">
    <div class="footer-logo">
      <img src="assets/images/logo/logo.svg" alt="logo-image" width="144" height="65">
    </div>

    <div class="footer-menu">
      <div class="nav-row">
        <a href="#aboutCompany" aria-label="footer ПРО НАС">ПРО НАС</a>
        <a href="#services" aria-label="footer ПОСЛУГИ">ПОСЛУГИ</a>
        <a href="#creating" aria-label="footer СПІВПРАЦЯ">СПІВПРАЦЯ</a>
        <a href="#address" aria-label="footer КОНТАКТИ">КОНТАКТИ</a>
      </div>

      <div class="footer-info">
        <div class="phone-number" aria-label="footer 0(800) 33-11-35">0(800) 33-11-35</div>
        <div class="email" aria-label="footer scsputnik@ukr.net">scsputnik@ukr.net</div>
      </div>
    </div>

    <div class="footer-btn-wrapper">
      <a href="https://www.facebook.com/sputnikosmd/" target="_blank" aria-label="footer facebook">
        <img src="assets/images/icons/social/facebook.svg" alt="facebook-icon" width="29" height="29">
      </a>
      <a href="https://www.instagram.com/uksputnik/" target="_blank" aria-label="footer instagram">
        <img src="assets/images/icons/social/instagram.svg" alt="instagram-icon" width="29" height="29">
      </a>
      <a href="//t.me/+380954250001" target="_blank" aria-label="footer telegram">
        <img src="assets/images/icons/social/telegram.svg" alt="telegram-icon" width="29" height="29">
      </a>
    </div>
  </div>
</div>

