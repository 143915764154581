<div class="container">
  <div class="wrap-title">
    <h2>
      <span class="color-title">ВІДГУКИ</span> НАШИХ КЛІЄНТІВ <br>КАЖУТЬ САМІ ЗА СЕБЕ
    </h2>
    <p>Подивіться, що люди думають про нашу роботу.</p>
  </div>

  <div class="wrap-content" *ngIf="comments.length > 0">
    <div class="section-button"  (click)="goLeft()">
      <img src="assets/images/icons/button/left-icon.svg" alt="left-icon" width="12" height="20">
    </div>

    <div class="content-wrap" #commentsList>
      <div class="content"  *ngFor="let comment of comments">
        <div class="text">
          <div class="stars">
            <div class="one" *ngFor="let star of array(comment.starsCount)">
              <img src="assets/images/icons/comments/Star.svg" alt="star-icon" width="18" height="18">
            </div>
          </div>

          <div class="text-description">
            <p>
              {{comment.description}}
            </p>
          </div>
        </div>

        <div class="photo">
          <img [src]="comment.photo" alt="photo" width="483" height="238">
          <div class="part-4"><span>{{comment.name}}</span>{{ comment.job}}</div>

          <div class="wrap-btn" (click)="openComments()">
            <div class="btn">Інші відгуки</div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-button" (click)="goRight()">
      <img src="assets/images/icons/button/right-icon.svg" alt="right-icon" width="12" height="20">
    </div>
  </div>

  <div class="wrap-content-mobile" *ngFor="let comment of comments">
    <div class="header-section">
      <div class="section-button" (click)="goLeft()">
        <img src="assets/images/icons/button/left-icon.svg" alt="left-icon" width="12" height="20">
      </div>

      <div class="photo-mob">
        <img [src]="comment.photo" alt="photo" width="483" height="238">
      </div>
      <div class="section-button"  (click)="goRight()">
        <img src="assets/images/icons/button/right-icon.svg" alt="right-icon" width="12" height="20">
      </div>
    </div>

    <div class="name">
      <div class="part-4"><span>{{comment.name}}</span>{{ comment.job}}</div>
    </div>

    <div class="stars">
      <div class="one" *ngFor="let star of array(comment.starsCount)">
        <img src="assets/images/icons/comments/Star.svg" alt="star-icon" width="18" height="18">
      </div>
    </div>

    <div class="description">
      <p>
        {{comment.description}}
      </p>
    </div>

    <div class="wrap-btn" (click)="openComments()">
      <div class="btn">Інші відгуки</div>
    </div>
  </div>
</div>
