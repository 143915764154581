<div class="container">
  <div class="content-wrapper">
    <h2>НАШІ СПІВРОБІТНИКИ ПРАЦЮЮТЬ</h2>
    <h2 class="color-title">24/7</h2>
    <div class="part-4">Аварійна служба та диспетчери готові завжди до вас прийти на допомогу!</div>
  </div>

  <div class="content-img">
    <img src="assets/images/section-background/2.png" alt="content-img" width="948" height="625">
  </div>
</div>

<div class="mobile-container">
  <div class="content-img-mobile">
    <img src="assets/images/section-background/mobile-baground2.png" alt="content-img" width="393" height="563">
  </div>

  <div class="content-wrapper-mobile">
    <h2>НАШІ СПІВРОБІТНИКИ ПРАЦЮЮТЬ</h2>
    <h2 class="color-title">24/7</h2>
    <div class="text part-4">Аварійна служба та диспетчери готові завжди до вас прийти на допомогу!</div>
  </div>
</div>
