<div class="container">
  <div class="wrap-image">
    <img src="assets/images/section-background/color-round.png" alt="color-round-icon">
  </div>

  <div class="wrap-description">
    <h2>КОМПАНІЯ <span class="color-title">СУПУТНИК</span></h2>
    <p>
      Компанія "Спутник" - це зручний сервіс для Керівника ОСББ.
      Делегуйте усю складну та рутинну роботу професіоналам і звільніть свій час
      для успішного керівництва Вашим будинком.
    </p>
    <p>
      Наша цілодобова диспетчерська служба звільнить
      Вас від телефонних дзвінків у режимі 24/7, та пошуку необхідних спеціалістів.
    </p>
    <p>
      Команда Спутник вже понад 10 років надає послуги з аварійно-технічного обслуговування
      мереж електрики та сантехніки, технічного обслуговування ліфтів та бухгалтерського супроводу ОСББ.
    </p>
    <p>
      Довірте справу професіоналам!
    </p>
  </div>

  <div class="wrap-team" *ngIf="photos.length > 0">
    <h2>НАША <span class="color-title">КОМАНДА</span></h2>

    <section class="team-photo">
      <div class="section-button"  (click)="goLeft()">
        <img src="assets/images/icons/button/left-icon.svg" alt="left-icon">
      </div>

      <div class="wrapper-photos" #photoList>
        <div class="photo-card" *ngFor="let photo of photos">
          <img [src]="photo.imgUrl" alt="photo-card" width="358" height="358">
          <p>{{photo.name}}</p>
          <p>{{photo.job}}</p>
        </div>
      </div>

      <div class="section-button" (click)="goRight()">
        <img src="assets/images/icons/button/right-icon.svg" alt="right-icon">
      </div>
    </section>
  </div>

  <div class="wrap-services" id="services">
    <h2>НАШІ <span class="color-title">ПОСЛУГИ</span></h2>
    <div class="items">

      <div class="service-item">
        <img src="assets/images/icons/1.svg" alt="service-icon">
        <p>Бухгалтерський облік ОСББ</p>
      </div>

      <div class="service-item">
        <img src="assets/images/icons/2.svg" alt="service-icon">
        <p>Аварійна служба ОСББ</p>
      </div>

      <div class="service-item">
        <img src="assets/images/icons/3.svg" alt="service-icon">
        <p>Обслуговування ліфтів</p>
      </div>

      <div class="service-item">
        <img src="assets/images/icons/4.svg" alt="service-icon">
        <p>Обслуговування водопостачання</p>
      </div>

      <div class="service-item">
        <img src="assets/images/icons/5.svg" alt="service-icon">
        <p>Технічне обслуговування сантехнічних і електричних мереж</p>
      </div>

      <div class="service-item">
        <img src="assets/images/icons/6.svg" alt="service-icon">
        <p>Юридичні послуги</p>
      </div>

      <div class="service-item">
        <img src="assets/images/icons/7.svg" alt="service-icon">
        <p>Створення ОСББ</p>
      </div>
    </div>
  </div>

  <div class="wrapper-btn" (click)="openDialog()">
    <div class="btn">Зв'язатися з нами</div>
  </div>
</div>

