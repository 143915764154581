<div class="container">
  <div class="content-img">
    <img src="assets/images/section-background/3.png" alt="content-image" width="788" height="545">
  </div>

  <div class="content-wrapper">
    <h2>СТВОРЕННЯ <span class="color-title">ОСББ</span></h2>
    <div class="part-4">Допомога та підтримка при створення нового ОСББ</div>
    <div class="btn" (click)="openDialog()">Зв'язатися з нами</div>
  </div>


</div>

<div class="mobile-container">
  <div class="content-img-mobile">
    <img src="assets/images/section-background/phone-background-3.png" alt="content-mobile-image" width="393" height="771">
  </div>

  <div class="content-wrapper-mobile">
    <h2>СТВОРЕННЯ <span class="color-title">ОСББ</span></h2>
    <div class="text part-4">Допомога та підтримка при створення нового ОСББ</div>
  </div>

  <div class="btn" (click)="openDialog()">Наші контакти</div>
</div>
