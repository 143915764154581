import {Component, inject} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

import {
  ContactsModalDialogComponent
} from "../../../shared/popups/contacts-modal-dialog/contacts-modal-dialog.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private matDialog = inject(MatDialog);

  openDialog() {
    this.matDialog.open(ContactsModalDialogComponent);
  }

}
