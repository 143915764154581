import {CommentInterface} from "../interfaces/comment.interface";

export const comments: CommentInterface[] = [
  {
    starsCount: 5,
    name: 'Любов Іванова Голова ',
    job: 'ОСББ "БУДІВЕЛЬНИК"',
    photo: 'assets/images/comments/1.png',
    description: 'Уклали договори з компанією "Супутник" ' +
      'щодо бухгалтерського обслуговування, аварійно-технічного ' +
      'внутрішньобудинкових мереж. Будь-коли фахівці приходять ' +
      'на допомогу, працює диспетчерська служба.' +
      ' З боку керівництва відчувається контроль ' +
      'за роботою всіх ланок. Головне в обслуговуванні,' +
      ' насамперед, повага до споживача, оперативність ' +
      'у вирішенні питань та демократична договірна вартість' +
      ' послуг. Дуже зручно налаштований зв\'язок взаємодії. ' +
      'Запитання можна легко вирішити безпосередньо з керівництвом ' +
      'через мережу інтернет, а також отримати консультацію. Це величезний плюс!'
  },
  // {
  //   starsCount: 5,
  //   name: 'Алла Шойхет',
  //   photo: 'assets/images/comments/inkognotio.jpg',
  //   description: 'Працюємо з Компанією з 2017 року з бухгалтерського обліку, ' +
  //     'технічного та аварійного обслуговування мереж. Виконували монтажні роботи.' +
  //     ' Усі чітко, вчасно, без проблем. Починала з одним будинком, зараз п\'ять будинків у віданні Супутника.\n' +
  //     'Дуже дякую за Вашу працю 💖'
  // }
]
